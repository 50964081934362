.audioSeekBarContainer {
    flex-grow: 0.85;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.barContainer {
    width: 100%;
    height: 8px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
}

.playedBarPart, .remainingBarPart {
    background: white;
}

.playedBarPart {
    border-radius: 10px 0 0 10px;
    width: 30%;
    opacity: 60%;
}

.remainingBarPart {
    border-radius: 0 10px 10px 0;
    width: 70%;
    opacity: 30%;
}

.timeContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.playedTimeLabel, .remainingTimeLabel {
    opacity: 65%;
}

@media screen and (max-width: 992px) {
    .audioSeekBarContainer {
      width: 100%;
      flex-grow: 0.2;
    }
}
