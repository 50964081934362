.formContainer {
    max-width: 70%;
}

.formContainer > form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.textInputContainer {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
}