.booksFilterPanel {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-around;
}

.levelFilterContainer, .sortingSelectorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
}
