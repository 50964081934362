.audioPlayerContainer {
  display: flex;
  justify-content: space-between;
	padding-top: 2%;
	padding-bottom: 2%;
	padding-left: 5%;
	padding-right: 5%;
	background-color: #c73f2e;
	color: white;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 100%;
}

.audioPlayerLeftSide > div {
  margin-bottom: 2%;
}

.audioPlayerRightSide {
  display: flex;
  justify-content: center;
  align-items: center;
	gap: 10%;
}

@media screen and (max-width: 992px) {
  .audioPlayerContainer {
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 200px;
  }
}
