html {
    height: auto;
    min-height: 100vh;
}

body {
    height: auto;
    min-height: 100vh;
}

div#root {
    height: auto;
    min-height: 100vh;
}
