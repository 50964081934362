.bookListItem {
    display: flex;
    justify-content: space-between;
}

.bookLeftSide > div {
    margin-bottom: 2%;
}

.bookRightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
}