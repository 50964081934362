.audioListItem {
  display: flex;
  justify-content: space-between;
}

.audioLeftSide > div {
  margin-bottom: 2%;
}

.audioRightSide {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
}